import {AxiosRequestConfig} from 'axios';

export const REACT_APP_BASE_API = 'https://api.housecall.com.ph/api/';
export const REACT_APP_BASE_WEB_URL = 'https://app.housecall.com.ph/';
export const MERCHANT_CODE = 'PH01684';
export const RESPONSE_URL = 'https://api.housecall.com.ph/api/payment/request';
export const BACKEND_URL = 'https://api.housecall.com.ph/api/payment/callback';
export const IPAY88_PAYMENT_ENTRY_URL = 'https://payment.ipay88.com.ph/epayment/entry.asp';
export const MAJOR_VERSION = '1';
export const MINOR_VERSION = '5';
export const BUILD_NUMBER = '1281';
export const FCM_API_KEY = 'AIzaSyDo-uTa7BtXiIXub0JtGH5atEkZ0hztU7o';
export const FCM_AUTH_DOMAIN = 'housecall-cb293.firebaseapp.com';
export const FCM_PROJECT_ID = 'housecall-cb293';
export const FCM_STORAGE_BUCKET = 'housecall-cb293.appspot.com';
export const FCM_MESSAGING_SENDER_ID = '52718157130';
export const FCM_APP_ID = '1:52718157130:web:6e6578ef5638d100e215b3';
export const FCM_MEASUREMENT_ID = 'G-KYQELVY4CC';
export const FCM_WEB_VAPID_KEY = 'BNiUUxPsmYdow_fz1dUfgLYMgQW8TopAg5lxA6-D51B1wqNsHQY7XJ80EfL62tiLif3sJFmQZ0r-wQXjXqpeIds';

export const apiConfig: AxiosRequestConfig = {
  withCredentials: false,
  baseURL: REACT_APP_BASE_API,
  headers: {
    'Content-Type': 'application/json',
  },
};
